// services/MessageService.js
import BaseService from '/entrypoints/services/BaseService.js';
import qs from 'qs';

class MessageService extends BaseService {
  constructor() {
    super('/messages');
  }

  async sendMessage(conversationId, content, contentHtml) {
    return this.create(
      qs.stringify({
        content,
        contentHtml,
        origin: 'chat_app',
        conversation_id: conversationId,
      })
    );
  }
}

export default new MessageService();
